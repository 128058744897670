/*Bhoomika Nagendra - 1002129530, Nuthan Rajeshwar Rao Neelakantam- 1002163021
Srinivasa Bhavani Padala - 1002172714, Rana Saidhar Reddy Palreddy - 1002169869
Deepthi Pamisetty - 1002063877*/
.message-input input {
    flex-grow: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-right: 10px;
}

.message-input button {
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.messages-page {
    display: flex;
    height: 100vh;
}

.contacts-list {
    width: 30%;
    border-right: 1px solid #ccc;
    overflow-y: auto;
}

.contact {
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #ccc;
}

.contact:hover {
    background-color: #f0f0f0;
}

.chat-window {
    width: 70%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    padding-bottom: 60px;

}

.chat-messages {
    flex-grow: 1;
    overflow-y: auto;
}

.message-input {
    display: flex;
    margin-top: 10px;
}


.message-input button:hover {
    background-color: #45a049;
}

/* Messages.css */
.messages-page {
    display: flex;
    height: 100vh;
}

.contacts-list {
    flex: 1;
    overflow-y: auto;
    border-right: 1px solid #ccc;
}

.chat-window {
    flex: 3;
    display: flex;
    flex-direction: column;
}

.chat-messages {
    flex-grow: 1;
    overflow-y: auto;
}

.message-input {
    margin-top: auto;
}

.message-sent, .message-received {
    padding: 10px;
    margin: 5px;
    border-radius: 10px;
    width: fit-content;
    max-width: 60%;
}

.message-sent {
    align-self: flex-end;
    background-color: #DCF8C6; /* Light green for sent messages */
    margin-right: none;
    margin-left: auto; /* Pushes the message to the right */
}

.message-received {
    align-self: flex-start;
    background-color: #ECECEC; /* Light grey for received messages */

}