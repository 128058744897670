/*Bhoomika Nagendra - 1002129530, Nuthan Rajeshwar Rao Neelakantam- 1002163021
Srinivasa Bhavani Padala - 1002172714, Rana Saidhar Reddy Palreddy - 1002169869
Deepthi Pamisetty - 1002063877*/
.dashboard {
    padding: 20px;
}

.dashboard h1 {
    text-align: center;
    margin-bottom: 20px;
}

.dashboard>div {
    margin-bottom: 20px;
    background-color: #f4f4f4;
    padding: 15px;
    border-radius: 5px;
}

.assigned-tasks ul {
    list-style-type: none;
    padding: 0;
}

.assigned-tasks li {
    margin-bottom: 10px;
}

.weekly-reports form {
    display: flex;
    flex-direction: column;
}

.weekly-reports textarea {
    margin-bottom: 10px;
    resize: vertical;
}

.weekly-reports button {
    align-self: flex-end;
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.weekly-reports button:hover {
    background-color: #45a049;
}

.completed {
    text-decoration: line-through;
    color: #4CAF50;
}

.assigned-tasks button {
    margin-left: 10px;
    padding: 5px 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.assigned-tasks button:hover {
    background-color: #45a049;
}



.react-calendar__tile--active .deadline,
.react-calendar__tile--active:hover .deadline,
.deadline {
    background-color: #595959 !important;
    color: white !important;
}

.content-check {
    margin: 20px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.content-textarea {
    width: 100%;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.check-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.check-button:hover {
    background-color: #0056b3;
}

.api-result {
    margin-top: 20px;
    padding: 20px;
    background-color: #f8f9fa;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.block-result {
    margin-top: 10px;
    padding: 10px;
    background-color: #fff;
    border: 1px solid #eee;
}