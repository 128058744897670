/*Bhoomika Nagendra - 1002129530, Nuthan Rajeshwar Rao Neelakantam- 1002163021
Srinivasa Bhavani Padala - 1002172714, Rana Saidhar Reddy Palreddy - 1002169869
Deepthi Pamisetty - 1002063877*/
.navbar {
    background-color: #333;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.nav-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1200px;
    padding: 0 20px;
}

.nav-logo {
    color: #fff;
    text-decoration: none;
    font-size: 1.5rem;
}

.nav-menu {
    list-style: none;
    display: flex;
    align-items: center;
    margin: 0;
}

.nav-item {
    margin-left: 20px;
}

.nav-link {
    color: #fff;
    text-decoration: none;
    padding: 10px 15px;
    border-radius: 5px;
    transition: background-color 0.2s ease;
}

.nav-link:hover {
    background-color: #4CAF50;
}