/*Bhoomika Nagendra - 1002129530, Nuthan Rajeshwar Rao Neelakantam- 1002163021
Srinivasa Bhavani Padala - 1002172714, Rana Saidhar Reddy Palreddy - 1002169869
Deepthi Pamisetty - 1002063877*/
.homepage {
    font-family: Arial, sans-serif;
    color: #333;
    line-height: 1.6;
}

.header {
    background-color: #4CAF50;
    color: white;
    padding: 20px;
    text-align: center;
}

.intro,
.how-it-works {
    padding: 20px;
    text-align: center;
}

.steps {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.step {
    background-color: white;
    padding: 15px;
    margin: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    flex-basis: 45%;
    text-align: left;
}

footer {
    background-color: #333;
    color: white;
    text-align: center;
    padding: 10px;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
}

.content-wrapper {
    position: relative;
    min-height: 100vh;
    padding-bottom: 50px;
}