/*Bhoomika Nagendra - 1002129530, Nuthan Rajeshwar Rao Neelakantam- 1002163021
Srinivasa Bhavani Padala - 1002172714, Rana Saidhar Reddy Palreddy - 1002169869
Deepthi Pamisetty - 1002063877*/
.aboutus {
    padding: 20px;
    text-align: center;
}

.aboutus h1 {
    margin-bottom: 20px;
    color: #333;
}

.aboutus p {
    margin-bottom: 10px;
    color: #666;
    line-height: 1.6;
}