/*Bhoomika Nagendra - 1002129530, Nuthan Rajeshwar Rao Neelakantam- 1002163021
Srinivasa Bhavani Padala - 1002172714, Rana Saidhar Reddy Palreddy - 1002169869
Deepthi Pamisetty - 1002063877*/
.login-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f4f4f4;
}

.login-form {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 300px;
}

.login-form h2 {
    text-align: center;
    margin-bottom: 20px;
}

.form-group {
    margin-bottom: 15px;
    margin-right: 10px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
}

.form-group input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.login-form button {
    width: 100%;
    padding: 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
}

.login-form button:hover {
    background-color: #45a049;
}

.login-options {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
}

.login-options a {
    color: #4CAF50;
    text-decoration: none;
}

.login-options a:hover {
    text-decoration: underline;
}