/*Bhoomika Nagendra - 1002129530, Nuthan Rajeshwar Rao Neelakantam- 1002163021
Srinivasa Bhavani Padala - 1002172714, Rana Saidhar Reddy Palreddy - 1002169869
Deepthi Pamisetty - 1002063877*/
.profile-page {
    padding: 20px;
    text-align: center;
}

.profile-page h1 {
    margin-bottom: 20px;
    color: #333;
}

.profile-form {
    background-color: #f8f8f8;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: 0 auto;
    text-align: left;
}

.profile-form label {
    display: block;
    margin-bottom: 10px;
}

.profile-form input {
    width: calc(100% - 10px);
    padding: 5px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.profile-form button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.profile-form button:hover {
    background-color: #45a049;
}