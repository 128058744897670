/*Bhoomika Nagendra - 1002129530, Nuthan Rajeshwar Rao Neelakantam- 1002163021
Srinivasa Bhavani Padala - 1002172714, Rana Saidhar Reddy Palreddy - 1002169869
Deepthi Pamisetty - 1002063877*/
.services {
    padding: 20px;
    text-align: center;
}

.services h1 {
    margin-bottom: 30px;
    color: #333;
}

.service-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.service-item {
    width: 30%;
    min-width: 250px;
    padding: 15px;
    background-color: #f8f8f8;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.service-item h2 {
    margin-bottom: 10px;
    color: #444;
}

.service-item p {
    color: #666;
    line-height: 1.5;
}