/*Bhoomika Nagendra - 1002129530, Nuthan Rajeshwar Rao Neelakantam- 1002163021
Srinivasa Bhavani Padala - 1002172714, Rana Saidhar Reddy Palreddy - 1002169869
Deepthi Pamisetty - 1002063877*/
.professor-dashboard {
    padding: 20px;
}

.professor-dashboard h1 {
    text-align: center;
    margin-bottom: 20px;
}

.report-review {
    background-color: #f4f4f4;
    padding: 15px;
    border-radius: 5px;
}

.reports {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.report {
    background-color: white;
    padding: 10px;
    margin: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.report textarea.feedback {
    width: calc(100% - 20px);
    margin-top: 10px;
    margin-bottom: 10px;
    resize: vertical;
    height: 100px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.report textarea {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
    resize: vertical;
}

.report button {
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.report button:hover {
    background-color: #45a049;
}

.professor-dashboard {
    padding: 20px;
}

.professor-dashboard h1 {
    text-align: center;
    margin-bottom: 20px;
}

.task-assignment {
    background-color: #f4f4f4;
    padding: 15px;
    border-radius: 5px;
    margin-bottom: 20px;
}

.task-assignment form {
    display: flex;
    flex-direction: column;
}

.task-assignment label {
    margin-top: 10px;
}

.task-assignment input,
.task-assignment select {
    padding: 8px;
    margin-top: 5px;
}

.task-assignment button {
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
}

.task-assignment button:hover {
    background-color: #45a049;
}

.professor-dashboard {
    font-family: Arial, sans-serif;
    margin: 20px;
}

.navigation-buttons {
    margin-bottom: 20px;
}

.navigation-buttons a {
    margin-right: 10px;
    padding: 10px 15px;
    background-color: #4ea688;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    cursor: pointer;
}

.navigation-buttons a:hover {
    background-color: #3a7d63;
}

.task-assignment,
.report-review,
#view-assigned-tasks,
#view-weekly-reports {
    margin-bottom: 40px;
}

.task-assignment form,
.report-review .report {
    margin-top: 20px;
}

.task-assignment label,
.report-review label {
    display: block;
    margin-bottom: 5px;
}

.task-assignment input[type="text"],
.task-assignment input[type="date"],
.task-assignment select,
.report-review textarea {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.task-assignment button,
.report-review button {
    background-color: #1a936f;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.task-assignment button:hover,
.report-review button:hover {
    background-color: #157a5b;
}

.professor-dashboard {
    margin: 20px;
}

.navigation-buttons {
    margin-bottom: 20px;
}

.navigation-buttons a {
    margin-right: 10px;
    padding: 10px;
    background-color: #4ea688;
    color: white;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
}

.navigation-buttons a:hover {
    background-color: #3b7f5b;
}

.task-assignment,
.report-review,
#view-assigned-tasks,
#view-weekly-reports {
    margin-bottom: 40px;
}

.task-assignment form,
.report-review .report,
.assigned-tasks .task {
    margin-top: 10px;
    padding: 20px;
    border-radius: 5px;
}

.task-assignment label,
.report-review label {
    display: block;
    margin-bottom: 5px;
}

.task-assignment input[type="text"],
.task-assignment input[type="date"],
.task-assignment select,
.report-review textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.task-assignment button,
.report-review button {
    background-color: #4ea688;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.task-assignment button:hover,
.report-review button:hover {
    background-color: #3b7f5b;
}

.report-review .report textarea {
    height: 100px;
}

.assigned-tasks {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.task-card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: calc(33.333% - 20px);
    transition: transform 0.3s ease;
}

.task-card:hover {
    transform: translateY(-5px);
}

.task-header {
    background-color: #4ea688;
    color: #fff;
    padding: 10px 15px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.task-header h3 {
    margin: 0;
}

.priority {
    padding: 5px 10px;
    border-radius: 20px;
    font-size: 0.8rem;
    text-transform: uppercase;
}

.priority.high {
    background-color: #ff4d4d;
}

.priority.medium {
    background-color: #ffcc00;
}

.priority.low {
    background-color: #99cc00;
}

.task-body {
    padding: 15px;
}

.task-footer {
    padding: 10px 15px;
    text-align: right;
}

.complete-btn {
    background-color: #4ea688;
    color: #fff;
    border: none;
    padding: 8px 15px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.complete-btn:hover {
    background-color: #3b7f5b;
}

.assigned-tasks .task,
.weekly-reports .report {
    background-color: #f4f4f4;
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 10px;
}

.assigned-tasks .task .task-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.assigned-tasks .task .priority {
    padding: 5px 10px;
    border-radius: 15px;
    color: white;
    font-size: 0.8em;
    text-transform: uppercase;
}

.assigned-tasks .task .priority.low {
    background-color: #4caf50;
}

.assigned-tasks .task .priority.medium {
    background-color: #ff9800;
}

.assigned-tasks .task .priority.high {
    background-color: #f44336;
}

.weekly-reports .report {
    background-color: #f2f2f2;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
}