/*Bhoomika Nagendra - 1002129530, Nuthan Rajeshwar Rao Neelakantam- 1002163021
Srinivasa Bhavani Padala - 1002172714, Rana Saidhar Reddy Palreddy - 1002169869
Deepthi Pamisetty - 1002063877*/
.contactus {
    padding: 20px;
    text-align: center;
}

.contactus h1 {
    margin-bottom: 20px;
    color: #333;
}

.contactus p {
    margin-bottom: 10px;
    color: #666;
    line-height: 1.6;
}

.contact-info p {
    background-color: #f4f4f4;
    padding: 10px;
    border-radius: 5px;
    margin: 10px 0;
}